<template>
  <div>
    <Divider/>

    <v-row no-gutters style="justify-content: right; margin-top: 16px">
      <ConfirmationButton :loading="loading" @onClick="$emit('onConfirm')"/>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'OrganizzeModalFooter',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
  },
  directives: {},
  components: {
    Divider: () => import('@/views/components/organizze/divider'),
    ConfirmationButton: () => import('@/views/components/organizze/confirmationButton'),
  },
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>